@import "../../../../global/colors";
@import "../../../../global/typography";

.benefits-form {
    padding: 24px;
    border-radius: 16px;
    background-color: $grey-winter-bg-01;

    &__form-heading {
      @include heading-h4($color: $winter);
    }
  
    .select__panel {
        border: 1px solid $grey-winter-dark;
    }
  
    .select__panel--phone {
      background-color: $grey-winter-bg-02;
  
      &:hover {
        border: 1px solid #08f;
        cursor: pointer;
      }
    }
  
    .input {
      flex: 0 0 48%;
      margin-bottom: 60px;
  
      @media(max-width: 576px) {
        flex: 0 0 100%;
        margin-bottom: 40px;
      }
    }
  
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: white;
    }
  
    .input__body {
      background-color: $grey-winter-bg-02;
      border: 1px solid $grey-winter-dark;
      color: #ffffff;
      transition: border 0.3s linear;
  
      &:hover {
        border: 1px solid #08f;
        cursor: pointer;
      }
  
      &:focus {
        border: 1px solid #08f;
      }
    }
  
    .input__label {
      font-weight: 700;
      color: $grey-winter;
      margin-bottom: 8px;
    }
  
    .select__panel-number--fill {
      color: $grey-winter;
    }
  
    .select__input {
      background: transparent;
      color: $grey-winter;
      padding: 0 16px;
    }

    .select__phone {
        border-right: 1px solid $grey-winter-dark;
    }
  
    &__button-wrapper {
      flex: 0 0 100%;
      display: flex;
      justify-content: flex-end;
  
      @media(max-width: 576px) {
        justify-content: center;
      }
    }
  
    &__button {
      position: relative;
      padding: 10px 60px;
      font-size: 16px;
      color: $dubai;
      font-weight: 500;
      background: $grey-winter;
      border-radius: 6px;
      outline: none;
      border: none;
      font-family: Montserrat, sans-serif;
      transition: all 0.2s linear;
  
      @media(max-width: 576px) {
        width: 100%;
        max-width: 285px;
      }
    }
  
    &__button:hover {
      cursor: pointer;
      background: $grey-winter;
    }
  
    &__button:focus {
      background: $grey-winter;
    }
  
    &__loader {
      left: 15px;
      top: 15%;
      position: absolute;
      height: 25px;
      width: 25px;
      margin-left: 10px;
      border-radius: 50%;
      border-top: 2px solid $dubai;
      border-right: 2px solid transparent;
      animation: spinner 1s linear infinite;
    }
  }
  
  @keyframes spinner {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }